import React, { useLayoutEffect } from "react"
import { Row } from "react-grid-system"
import H3 from "../atoms/typography/H3"
import PrimaryButton from "../atoms/buttons/PrimaryButton"
import BodyText from "../atoms/typography/BodyText"
import { styled } from "styled-components"
import { MediaQuery } from "../../utils/design-helpers"
import { UIConfig } from "../../edge/UIConfig"
import SpinnerIcon from "../atoms/icons/SpinnerIcon"
import { ConfigState } from "../../edge/Config"
import { WebguiConfigExtraData } from "../../edge/EdgeProvider"
import { ConfigValueWithExtraData } from "../../edge/Config"
import { CheckmarkIcon } from "../atoms/icons/CheckboxIcons"
import { CrossIcon } from "../atoms/icons/CrossIcon"
import { theme } from "../theme"
import ResetIcon from "../atoms/icons/ResetIcon"

const DURATION_TEMPORARY_BANNER_MS = 2000

type Value = ConfigValueWithExtraData<WebguiConfigExtraData>
type Values = Value[]

type TemporaryBanner = { type: "push_success"; values: Values } | { type: "discard"; values: Values } | undefined

interface Props {
  uiConfig: UIConfig<WebguiConfigExtraData>
  headerHeight: number
}

const Banner = styled.div<{ top: number }>`
  top: ${({ top }) => top}px;
  position: sticky;
  z-index: 1;
  border-bottom: 4px solid ${({ theme }) => theme.gradients.blueBorder};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(119, 119, 119, 0.25);
`
const ItemsContainer = styled.div<{ padding?: number; xsGap?: number }>`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  align-items: center;
  padding: ${({ padding }) => padding ?? 20}px 32px;

  ${MediaQuery.XS} {
    flex-direction: column;
    gap: ${({ xsGap }) => xsGap ?? 16}px;
  }

  ${MediaQuery.XS} {
    padding: 16px 16px;
  }
`

const ErrorStateChangesContainer = styled.div`
  display: flex;
  width: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 9px;
  flex-shrink: 0;
`

const ErrorStateListContainer = styled.div`
  display: flex;
  width: 200px;
  max-width: 250px;
  flex-direction: column;
  align-items: flex-start;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 16px;

  ${MediaQuery.XS} {
    justify-content: center;
    align-self: stretch;
  }
`

const TextIconContainer = styled.div`
  display: flex;
  max-width: 300px;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex-wrap: wrap;
`

const ApplyConfigBanner: React.FunctionComponent<Props> = ({ uiConfig, headerHeight }) => {
  const state = uiConfig.state()

  const [prevState, setPrevState] = React.useState<ConfigState<WebguiConfigExtraData>>({ state: "read" })
  const [tmpBannerWithIndex, setTmpBannerWithIndex] = React.useState<[TemporaryBanner, number]>([undefined, 0])
  const [tmpBanner, tmpBannerIndex] = tmpBannerWithIndex

  // Using just layout here does sometimes make the transition from persistent
  // banner to temporary banner to flicker, that is, there is a frame render
  // between the states that momentarily contains no banner.
  useLayoutEffect(() => {
    if (state.state !== prevState.state) {
      let newTmpBanner: TemporaryBanner = undefined
      if (prevState.state == "push" && state.state == "normal") {
        newTmpBanner = { type: "push_success", values: prevState.values }
      }
      if (state.state == "discard") {
        newTmpBanner = { type: "discard", values: state.values }
      }

      if (newTmpBanner !== undefined) {
        const newIndex = tmpBannerIndex + 1
        setTmpBannerWithIndex([newTmpBanner, newIndex])
        setTimeout(function () {
          setTmpBannerWithIndex((tmpBannerWithIndex) => {
            const [banner, index] = tmpBannerWithIndex
            // Don't remove any newer spawned temporary banners. For example,
            // if the end-user discards a value, the first temporary banner
            // is spawned and will be removed after DURATION_TEMPORARY_BANNER_MS.
            // If the user discards another value before this duration, the
            // first timeout trigger can be called after the second temporary
            // banner is spawned, and this check makes sure the old timeout
            // doesn't close the newer spawned temporary banner.
            return index === newIndex ? [undefined, index] : [banner, index]
          })
        }, DURATION_TEMPORARY_BANNER_MS)
      }
      setPrevState(state)
    }
  }, [state, prevState])

  const changedValuesComponent = (values: Values) => {
    const widgetOrigins = values.map((value) => value.extraData)
    const changedValuesCount = values.length
    const changedValuesOrigins = Array.from(new Set(widgetOrigins)).sort().join(", ")
    return (
      <Row nogutter align="center" style={{ gap: "8px" }}>
        <H3>
          {changedValuesCount} change{changedValuesCount > 1 ? "s" : ""} in:{" "}
        </H3>
        <BodyText>{changedValuesOrigins}</BodyText>
      </Row>
    )
  }

  // Persistent banners
  const persistentBannerContent = () => {
    switch (state.state) {
      case "read": {
        // Don't show anything while reading
        return undefined
      }
      case "readFailed": {
        // TODO: Implement
        return undefined
      }
      case "normal": {
        if (uiConfig.changedValues().length === 0) {
          return undefined
        }
        return (
          <ItemsContainer padding={8}>
            {changedValuesComponent(uiConfig.changedValues())}
            <ButtonsContainer>
              <PrimaryButton title="Apply changes" onClick={uiConfig.pushToDevice} />
              <PrimaryButton variant="light" title="Discard" onClick={uiConfig.discard} />
            </ButtonsContainer>
          </ItemsContainer>
        )
      }
      case "push": {
        return (
          <ItemsContainer>
            {changedValuesComponent(state.values)}
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <SpinnerIcon />
              <H3>
                Saving {state.appliedValues.length}/{state.values.length} changes...
              </H3>
            </div>
          </ItemsContainer>
        )
      }
      case "pushFailed": {
        // TODO: Fix font for error list
        return (
          <ItemsContainer padding={8}>
            {changedValuesComponent(uiConfig.changedValues())}
            <ErrorStateChangesContainer>
              {state.successfulValues.length !== 0 ? (
                <H3 color={theme.colors.greenDark}>
                  {state.successfulValues.length} change{state.successfulValues.length > 1 ? "s" : ""} saved
                  successfully
                </H3>
              ) : undefined}
              <ErrorStateListContainer>
                <H3 color={theme.colors.redDark}>
                  {state.failedValues.length} error{state.failedValues.length > 1 ? "s" : ""} occurred:
                </H3>
                {state.failedValues.map((failedValue) => {
                  const metadata = uiConfig.valueMetadata(failedValue.configValue.id)
                  if (metadata === undefined) {
                    return <></>
                  }
                  return (
                    <BodyText key={failedValue.configValue.id} color={theme.colors.redDark}>
                      {metadata.readableName} - {uiConfig.displayValue(failedValue.configValue)}
                    </BodyText>
                  )
                })}
              </ErrorStateListContainer>
            </ErrorStateChangesContainer>
            <ButtonsContainer>
              <PrimaryButton
                iconLeft={<ResetIcon color={theme.colors.white} />}
                title="Retry"
                onClick={uiConfig.pushToDevice}
              />
              <PrimaryButton variant="light" title="Discard" onClick={uiConfig.discard} />
            </ButtonsContainer>
          </ItemsContainer>
        )
      }
    }
  }

  const tmpBannerContent = () => {
    // Check for any temporary banners
    switch (tmpBanner?.type) {
      case "push_success": {
        return (
          <ItemsContainer padding={23} xsGap={22}>
            {changedValuesComponent(tmpBanner.values)}
            <TextIconContainer>
              <CheckmarkIcon color={theme.colors.greenDark} />
              <H3 color={theme.colors.greenDark}>All changes saved successfully</H3>
            </TextIconContainer>
          </ItemsContainer>
        )
      }
      case "discard": {
        return (
          <ItemsContainer padding={23} xsGap={22}>
            {changedValuesComponent(tmpBanner.values)}
            <TextIconContainer>
              <CrossIcon color={theme.colors.redDark} />
              <H3 color={theme.colors.redDark}>Changes discarded</H3>
            </TextIconContainer>
          </ItemsContainer>
        )
      }
    }
  }
  const bannerContent = persistentBannerContent() ?? tmpBannerContent()
  return <>{bannerContent && <Banner top={headerHeight}>{bannerContent}</Banner>}</>
}

export default ApplyConfigBanner
