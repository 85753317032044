import * as React from "react"
import { Container, Row, Col, Visible, Hidden } from "react-grid-system"
import styled from "styled-components"
import { SatcubeLogo } from "../../images/SatcubeLogo"
import { MediaQuery } from "../../utils/design-helpers"
import { Link } from "react-router-dom"
import H3 from "../atoms/typography/H3"
import { theme } from "../theme"
import LocationIcon from "../atoms/icons/LocationIcon"
import TerminalStatus from "../atoms/TerminalStatus"
import SignalIcon from "../atoms/icons/SignalIcon"
import TxIcon from "../atoms/icons/TxIcon"
import WifiIcon from "../atoms/icons/WifiIcon"
import HamburgerMenu from "../atoms/icons/HamburgerMenu"
import { Spacer, HorizontalSpacer, AutoSpacer } from "../atoms/spacers"
import { useEdgeApi } from "../../edge/EdgeProvider"
import { signalStrength } from "../../utils/signal-strength"

interface HeaderProps {
  isLoggedIn: boolean
  setOpenNavbar: (open: boolean) => void
  openNavbar: boolean
}

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-block: ${({ theme }) => theme.spacings.large};
  position: sticky;
  z-index: 5;
  top: 0;
  background-color: ${({ theme }) => theme.colors.greyDarkest};

  ${MediaQuery.XS} {
    padding: 0.75rem;
  }
`

const WebGUISubtitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
`

const LogoButton = styled.div`
  cursor: pointer;
`

const Content = styled.div`
  margin: 0 auto;
  padding-inline: ${(props) => props.theme.spacings.large};
  max-width: 100%;

  ${MediaQuery.XS} {
    padding-inline: 0;
  }
`

// TODO: get this from the API?
const PRODUCT_NAME = "Satcube Motion"

const Header: React.ForwardRefRenderFunction<HTMLDivElement, HeaderProps> = (
  { isLoggedIn, openNavbar, setOpenNavbar },
  ref,
) => {
  const { config, metrics } = useEdgeApi()
  const [locationOn, setLocationOn] = React.useState<boolean>(false)
  const [txOn, setTxOn] = React.useState<boolean>(false)

  const mobileHeaderColWidth = isLoggedIn ? 12 : 6
  const { connected } = useEdgeApi()

  const cOverN = metrics.useSignalStatusStrength()

  return (
    <HeaderWrapper ref={ref} id="header">
      <Container fluid>
        <Content>
          <Row justify="between" align="center" nogutter>
            <Col xs={mobileHeaderColWidth} sm={mobileHeaderColWidth} md={6} lg={6} xl={6}>
              <Row nogutter justify="between">
                <Link to="/" id="homepage">
                  <LogoButton>
                    <SatcubeLogo />
                  </LogoButton>
                </Link>
                {isLoggedIn && (
                  <Visible xs sm>
                    <HamburgerMenu isOpen={openNavbar} onClick={() => setOpenNavbar(!openNavbar)} />
                  </Visible>
                )}
              </Row>
              <Visible xs sm>
                <HorizontalSpacer spacing={0.5} />
              </Visible>
            </Col>
            <Col xs={mobileHeaderColWidth} sm={mobileHeaderColWidth} md={6} lg={6} xl={6}>
              {isLoggedIn ? (
                <Row nogutter justify="end" align="center">
                  <div>
                    <H3 color="white">Motion 000123</H3>
                    <div>
                      <TerminalStatus status={connected ? "connected" : "loading"} />
                    </div>
                  </div>
                  <Visible xs sm>
                    <AutoSpacer />
                  </Visible>
                  <Hidden xs sm>
                    <Spacer spacing={4} />
                  </Hidden>
                  <div>
                    <SignalIcon strength={cOverN ? signalStrength(cOverN) : 0} />
                  </div>
                  <Spacer spacing={0.5} />
                  <div onClick={() => setLocationOn(!locationOn)}>
                    <LocationIcon gpsOn={locationOn} />
                  </div>
                  <Spacer spacing={0.5} />
                  {/* TODO: buc_info.unmute doesn't exist yet, but it should be used here */}
                  <div onClick={() => setTxOn(!txOn)}>
                    <TxIcon on={txOn} />
                  </div>
                  <Spacer spacing={0.5} />
                  <div>
                    <WifiIcon on={config.useWifiEnabled("")?.deviceValue ?? false} />
                  </div>
                </Row>
              ) : (
                <Row nogutter justify="end" align="center">
                  <WebGUISubtitle>
                    <H3 color={theme.colors.white}>
                      {PRODUCT_NAME}
                      <br />
                      User Interface
                    </H3>
                  </WebGUISubtitle>
                </Row>
              )}
            </Col>
          </Row>
        </Content>
      </Container>
    </HeaderWrapper>
  )
}

export default React.forwardRef(Header)
