import * as React from "react"
import { theme } from "../../theme"
import { SignalStrengthWithZero } from "../../../types"

interface IconProps {
  strength: SignalStrengthWithZero
}

const SignalIcon: React.FC<IconProps> = ({ strength }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Signal-icon/four">
        <rect
          id="Rectangle 1"
          x="4"
          y="20.1172"
          width="4"
          height="5.88235"
          rx="1"
          fill={strength > 0 ? theme.colors.white : theme.colors.greyDark}
        />
        <rect
          id="Rectangle 2"
          x="10.666"
          y="15.4121"
          width="4"
          height="10.5882"
          rx="1"
          fill={strength > 1 ? theme.colors.white : theme.colors.greyDark}
        />
        <rect
          id="Rectangle 3"
          x="17.334"
          y="10.7061"
          width="4"
          height="15.2941"
          rx="1"
          fill={strength > 2 ? theme.colors.white : theme.colors.greyDark}
        />
        <rect
          id="Rectangle 4"
          x="24"
          y="6"
          width="4"
          height="20"
          rx="1"
          fill={strength > 3 ? theme.colors.white : theme.colors.greyDark}
        />
      </g>
    </svg>
  )
}

export default SignalIcon
