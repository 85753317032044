// Top level error for all Edge related errors
export class EdgeError extends Error {
  constructor(message: string) {
    super(message)
  }
}

// This error is used when a protobuf message have fields that are expected
// to exist but aren't present.
export class ProtoMissingFieldError extends EdgeError {
  constructor(protoMessage: string, field: string) {
    super(`protobuf message ${protoMessage} missing field ${field}`)
  }
}

// This error is used when metadata is not available for a specific resource.
// For example, if a config or metric value is accessed with a non existing
// id, this error is returned.
export class MissingMetadataError extends EdgeError {
  constructor(message: string) {
    super(message)
  }
}

// This error is used when any metadata is not available. Mostly used
// when the metadata hasn't been received from the device yet.
export class MetadataNotAvailableError extends EdgeError {
  constructor() {
    super("Device metadata is not available")
  }
}

// This error is used when writing a config value with
// a unexpected type.
export class UnexpectedConfigTypeError extends EdgeError {
  constructor(message: string) {
    super(message)
  }
}

// TODO: Substitute with MissingMetadataError
export class UnexpectedMetricValueError extends EdgeError {
  constructor(message: string) {
    super(message)
  }
}

// TODO: Substitute with MissingMetadataError
export class UnexpectedConfigValueError extends EdgeError {
  constructor(message: string) {
    super(message)
  }
}
