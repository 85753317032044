import { SignalStrengthWithZero } from "../types"

// The formulas for the signal strengths are documented in:
// https://gitlab.com/satcube/cloud/requirements/-/issues/19

const maxCN = 12.0

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max)
}

export function calcCNPercentage(cOverN: number): number {
  return Math.round(Math.sqrt(clamp(cOverN, 0.0, maxCN) / maxCN) * 100)
}

export function signalStrength(cOverN: number): SignalStrengthWithZero {
  const percentage = calcCNPercentage(cOverN)
  // Could probably do something like Math.round(percentage/25) here but
  // this doesn't yield the same type safety with SignalStrengthWithZero.
  if (percentage == 0) {
    return 0
  }
  if (percentage < 25) {
    return 1
  }
  if (percentage < 50) {
    return 2
  }
  if (percentage < 75) {
    return 3
  }
  return 4
}
