import * as React from "react"
import PowerIcon from "../../atoms/icons/PowerIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import { pages } from "../../../utils/pages"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import { VOLTAGE_FLOAT_DECIMAL_COUNT } from "../../constants"

const VOLTAGES_TOOLTIP = `
Each power rail powers a set of components in the system. 
Small deviations from the nominal voltage is expected and normal.
Large deviations may cause issues.
`

const VoltagesCard: React.FunctionComponent = () => {
  const { metrics } = useEdgeApi()

  const displayVoltage = (voltage?: number) =>
    voltage ? `${voltage.toFixed(VOLTAGE_FLOAT_DECIMAL_COUNT)} V` : undefined

  return (
    <SettingsOverviewCard title={pages.system.sections[4].name} icon={<PowerIcon />} tooltip={VOLTAGES_TOOLTIP}>
      <ReadOnlyTextField
        placeholder="-.- V"
        label="24V Power supply"
        value={displayVoltage(metrics.useRuntimeMonitorVoltageLevel24v())}
      />
      <ReadOnlyTextField
        placeholder="-.- V"
        label="5V Power supply"
        value={displayVoltage(metrics.useRuntimeMonitorVoltageLevel5v())}
      />
      <ReadOnlyTextField
        placeholder="-.- V"
        label="3.3V Power supply"
        value={displayVoltage(metrics.useRuntimeMonitorVoltageLevel3v3())}
      />
      <ReadOnlyTextField
        placeholder="-.- V"
        label="1.8V Power supply"
        value={displayVoltage(metrics.useRuntimeMonitorVoltageLevel1v8())}
      />
      <ReadOnlyTextField
        placeholder="-.- V"
        label="1.2V Power supply"
        value={displayVoltage(metrics.useRuntimeMonitorVoltageLevel1v2())}
      />
    </SettingsOverviewCard>
  )
}

export default VoltagesCard
