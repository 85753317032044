import React from "react"
import { Col, Row, Visible } from "react-grid-system"
import styled from "styled-components"
import { SignalStrengthWithZero } from "../../../types"
import SignalStrengthBars from "../../atoms/graphs/SignalStrengthBars"
import { HorizontalSpacer } from "../../atoms/spacers"
import H2 from "../../atoms/typography/H2"
import H4 from "../../atoms/typography/H4"
import { theme } from "../../theme"

const GraphContainerCol = styled(Col)`
  min-width: 210px;
  max-width: 250px;
`

const ColWithMaxWidth = styled(Col)`
  max-width: 120px !important;
`

const SignalStrengthChart: React.FunctionComponent<{ signalStrength?: SignalStrengthWithZero }> = (props) => {
  const signalStrengthToWord = (strength: SignalStrengthWithZero) => {
    switch (strength) {
      case 0:
        return "No signal"
      case 1:
        return "Low"
      case 2:
        return "Medium"
      case 3:
        return "High"
      case 4:
        return "High"
    }
  }

  return (
    <GraphContainerCol>
      <Row align="center" justify="center">
        <ColWithMaxWidth xs={6}>
          <SignalStrengthBars strength={props.signalStrength ?? 0} />
        </ColWithMaxWidth>
        <ColWithMaxWidth xs={6}>
          <H4>Strength:</H4>
          <H2 color={props.signalStrength !== undefined ? theme.colors.greyDark : theme.colors.greyDisabled}>
            {props.signalStrength !== undefined ? signalStrengthToWord(props.signalStrength) : "--"}
          </H2>
        </ColWithMaxWidth>
      </Row>
      <Visible xs sm>
        <HorizontalSpacer spacing={2} />
      </Visible>
    </GraphContainerCol>
  )
}

export default SignalStrengthChart
