import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import TxIcon24x24 from "../../atoms/icons/TxIcon24x24"
import ButtonRow from "../../molecules/card/ButtonRow"
import ToggleButton from "../../atoms/inputs/ToggleButton"
import TurnOffTxModal from "../modals/TurnTxOffModal"

interface Props {
  active: boolean
  onToggle: (active: boolean) => void
}

const TransmissionCard: React.FunctionComponent<Props> = ({ active, onToggle }) => {
  const [txConfirmationModalOpen, setTxConfirmationModalOpen] = React.useState<boolean>(false)

  const handleToggleTransmission = () => {
    if (active) {
      setTxConfirmationModalOpen(true)
    } else {
      onToggle(true)
    }
  }

  const handleConfirmTransmissionOff = () => {
    onToggle(false)
    setTxConfirmationModalOpen(false)
  }

  return (
    <>
      <SettingsOverviewCard title="Transmission" icon={<TxIcon24x24 />}>
        <ButtonRow label="Transmission" button={<ToggleButton active={active} onToggle={handleToggleTransmission} />} />
      </SettingsOverviewCard>
      {txConfirmationModalOpen && (
        <TurnOffTxModal
          open={txConfirmationModalOpen}
          onClose={() => setTxConfirmationModalOpen(false)}
          onConfirm={handleConfirmTransmissionOff}
        />
      )}
    </>
  )
}

export default TransmissionCard
