import React from "react"
import { Col, Row, Visible } from "react-grid-system"
import styled from "styled-components"
import { calcCNPercentage } from "../../../utils/signal-strength"
import CircleGraph from "../../atoms/graphs/CircleGraph"
import { HorizontalSpacer } from "../../atoms/spacers"
import H2 from "../../atoms/typography/H2"
import H4 from "../../atoms/typography/H4"
import { SIGNAL_TO_NOISE_DECIMAL_COUNT } from "../../constants"
import { theme } from "../../theme"

const GraphContainerCol = styled(Col)`
  min-width: 210px;
  max-width: 250px;
`

const ColWithMaxWidth = styled(Col)`
  max-width: 120px !important;
`

const CNChart: React.FunctionComponent<{ cOverN?: number }> = (props) => {
  const cOverNStr = props.cOverN ? props.cOverN.toFixed(SIGNAL_TO_NOISE_DECIMAL_COUNT) : undefined

  return (
    <GraphContainerCol>
      <Row align="center" justify="center">
        <ColWithMaxWidth xs={6}>
          <CircleGraph strength={props.cOverN ? calcCNPercentage(props.cOverN) : 0} />
        </ColWithMaxWidth>
        <ColWithMaxWidth xs={6}>
          <H4>C/N:</H4>
          <H2
            style={{ whiteSpace: "nowrap" }}
            color={props.cOverN != undefined ? theme.colors.greyDark : theme.colors.greyDisabled}
          >{`${cOverNStr ?? "--"} dB`}</H2>
        </ColWithMaxWidth>
      </Row>
      <Visible xs sm>
        <HorizontalSpacer spacing={2} />
      </Visible>
    </GraphContainerCol>
  )
}

export default CNChart
