import * as React from "react"
import TemperatureIcon from "../../atoms/icons/TemperatureIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import { pages } from "../../../utils/pages"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import { TEMPERATURES_FLOAT_DECIMAL_COUNT } from "../../constants"
import ButtonRowEditor from "../../molecules/inputs/ButtonRowEditor"

const TEMPERATURES_TOOLTIP = `
Note: Turning the fans off may cause the system to overheat, use fan settings with caution. 
When “Auto” is enabled, the fans activate when temperatures get too high, 
and deactivate when the system is within optimal operating temperatures. 
“Off” disables all fans, and “Max” runs all fans at max effect.
`

const WIDGET_ORIGIN_TEMPERATURES = "Temperatures"

const TemperaturesCard: React.FunctionComponent = () => {
  const { metrics, config } = useEdgeApi()

  const celsiusToFahrenheit = (celsius: number) => {
    const fahrenheit = (celsius * 9) / 5 + 32
    return fahrenheit
  }
  const displayCelsiusAndFahrenheit = (celsius?: number) =>
    celsius
      ? `${celsius.toFixed(TEMPERATURES_FLOAT_DECIMAL_COUNT)}°C / ${celsiusToFahrenheit(celsius).toFixed(TEMPERATURES_FLOAT_DECIMAL_COUNT)}°F`
      : undefined

  return (
    <SettingsOverviewCard
      title={pages.system.sections[3].name}
      icon={<TemperatureIcon />}
      tooltip={TEMPERATURES_TOOLTIP}
    >
      <ReadOnlyTextField
        placeholder="-- -"
        label="Parental board"
        value={displayCelsiusAndFahrenheit(metrics.useRuntimeMonitorPbTemp())}
      />
      <ReadOnlyTextField
        placeholder="-- -"
        label="Alpha motor"
        value={displayCelsiusAndFahrenheit(metrics.useMotorDiagnosticsAlphaWindingTemp())}
      />
      <ReadOnlyTextField
        placeholder="-- -"
        label="Beta motor"
        value={displayCelsiusAndFahrenheit(metrics.useMotorDiagnosticsBetaWindingTemp())}
      />
      <ReadOnlyTextField
        placeholder="-- -"
        label="BUC Amp 1"
        value={displayCelsiusAndFahrenheit(metrics.useBucTemperatureAmplifier1())}
      />
      <ReadOnlyTextField
        placeholder="-- -"
        label="BUC Amp 2"
        value={displayCelsiusAndFahrenheit(metrics.useBucTemperatureAmplifier2())}
      />
      <ReadOnlyTextField
        placeholder="-- -"
        label="UI board"
        value={displayCelsiusAndFahrenheit(metrics.useRuntimeMonitorUibTemp())}
      />
      <ButtonRowEditor label="Fans" editor={config.useFanControlMode(WIDGET_ORIGIN_TEMPERATURES)} />
    </SettingsOverviewCard>
  )
}

export default TemperaturesCard
