// Simplified wrapper over protobuf metric to simplify fetching of metrics

import { DeviceMetadata, MetricValueMetadata } from "./DeviceMetadata"
import * as metricsProto from "edge-proto/dist/edge/v1/metrics"
import { EdgeError, MissingMetadataError, ProtoMissingFieldError, UnexpectedMetricValueError } from "./Errors"
import dayjs from "dayjs"

type MetricValuesContainer = { [key: string]: metricsProto.MetricMessage }

interface MetricValue {
  toString(): string | UnexpectedMetricValueError
  toNumber(): number | UnexpectedMetricValueError
  toBool(): boolean | UnexpectedMetricValueError
  toEnum<T>(): T | UnexpectedMetricValueError
}

// Same as MetricValue interface, but returns undefined in case of any
// errors. Implementation of the interface takes an callback function
// which is called on any errors. The interface is usual for displaying
// values in react, so that no handling of errors is needed.
export interface MetricValueNoError {
  toString(): string | undefined
  toNumber(): number | undefined
  toBool(): boolean | undefined
  toEnum<T>(): T | undefined
}

// Contains the most recent received metric values. All operations
// on the object are immutable.
export interface Metrics {
  /**
   * Returns a metric value given a group and value id.
   *
   * @returns the metric value. The value
   * is undefined if the metric value hasn't been received from the
   * edge API yet or the metadata isn't available yet.
   */
  value(groupId: string, valueId: string): MetricValueNoError | undefined
  setMetadata(metadata: DeviceMetadata): Metrics
  // This function should be called when a new metric message is received
  // from the device and returns a new metrics set containing metrics
  // corresponding to the content of the metric message.
  update(metricMessage: metricsProto.MetricMessage): Metrics | ProtoMissingFieldError

  useStringValue(groupId: string, id: string): string | undefined
  useTimestampValue(groupId: string, id: string): dayjs.Dayjs | undefined
  useNumberValue(groupdId: string, id: string): number | undefined
  useBoolValue(groupId: string, id: string): boolean | undefined
  useEnumValue<T>(groupId: string, id: string): T | undefined
}

function metricValue(metricValueMetadata: MetricValueMetadata, metricValue: metricsProto.MetricValue): MetricValue {
  const value = metricValue.value

  const error = (msg: string): UnexpectedMetricValueError => {
    return new UnexpectedMetricValueError(`For metric ${metricValueMetadata.fullId} with value ${value}: ${msg}`)
  }

  const missingValueError = () => error("Metric value is missing in proto definition")

  const unexpectedTypeError = (expectedType: string) =>
    error(`Got unexpected type ${value?.$case}, but expected a ${expectedType}`)

  const toString = (): string | UnexpectedMetricValueError => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "stringValue":
        return value.stringValue
      case "floatValue":
        return value.floatValue.toString()
      case "boolValue":
        return value.boolValue.toString()
      case "int32Value":
        return value.int32Value.toString()
      case "uint32Value":
        return value.uint32Value.toString()
      case "timestampValue":
        return value.timestampValue.toString()
    }
  }

  const toNumber = (): number | UnexpectedMetricValueError => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "int32Value": {
        return value.int32Value
      }
      case "uint32Value": {
        return value.uint32Value
      }
      case "floatValue": {
        return value.floatValue
      }
      default:
        return unexpectedTypeError("int, uint or float")
    }
  }

  const toBool = (): boolean | UnexpectedMetricValueError => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "boolValue": {
        return value.boolValue
      }
      default:
        return unexpectedTypeError("bool")
    }
  }

  return {
    toString,
    toEnum<T>(): T | UnexpectedMetricValueError {
      const validator = metricValueMetadata.validator
      const stringValue = toString()

      if (stringValue instanceof UnexpectedMetricValueError) {
        return stringValue
      }

      switch (validator.$case) {
        case "enum":
          if (!validator.isValid(stringValue)) {
            return error(`Expected enum value to be one of ${validator.expectedValues}`)
          }
          return stringValue as T
        default:
          return unexpectedTypeError("enum")
      }
    },
    toNumber,
    toBool,
  }
}

function metricValueNoError(valueMetric: MetricValue, onError: (error: EdgeError) => void): MetricValueNoError {
  return {
    toString(): string | undefined {
      const value = valueMetric.toString()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    toEnum<T>(): T | undefined {
      const value = valueMetric.toEnum<T>()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    toNumber(): number | undefined {
      const value = valueMetric.toNumber()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    toBool(): boolean | undefined {
      const value = valueMetric.toBool()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
  }
}

function newMetricsWithValues(
  values: MetricValuesContainer,
  onError: (error: EdgeError) => void,
  metadata?: DeviceMetadata,
): Metrics {
  const valueWithError = function (
    groupId: string,
    valueId: string,
  ): MetricValue | undefined | MissingMetadataError | ProtoMissingFieldError {
    if (metadata === undefined) {
      return undefined
    }
    const metricMessage = values[groupId]
    if (metricMessage === undefined) {
      return undefined
    }
    const metricGroup = metadata.metricGroup(groupId)
    if (metricGroup instanceof MissingMetadataError) {
      return metricGroup
    }
    const metricValueMetadata = metricGroup.value(valueId)
    if (metricValueMetadata === undefined) {
      return new MissingMetadataError(`Could not find metric in group ${groupId} with id ${valueId}`)
    }
    const metricSet = metricMessage.metricSet
    if (metricSet === undefined) {
      return new ProtoMissingFieldError("MetricMesssage", "MetricGroup")
    }
    return metricValue(metricValueMetadata, metricSet.metricValues[metricValueMetadata.index])
  }

  const getValue = (groupId: string, valueId: string): MetricValueNoError | undefined => {
    const value = valueWithError(groupId, valueId)
    if (value instanceof EdgeError) {
      onError(value)
      return undefined
    }
    if (value === undefined) {
      return undefined
    }
    return metricValueNoError(value, onError)
  }

  return {
    value: getValue,
    setMetadata(metadata: DeviceMetadata): Metrics {
      return newMetricsWithValues(values, onError, metadata)
    },
    update(metricMessage: metricsProto.MetricMessage): Metrics | ProtoMissingFieldError {
      const metricGroup = metricMessage.metricSet
      if (metricGroup === undefined) {
        return new ProtoMissingFieldError("MetricMessage", "MetricGroup")
      }

      return newMetricsWithValues({ ...values, ...{ [metricGroup.id]: metricMessage } }, onError, metadata)
    },
    useStringValue(groupId: string, id: string): string | undefined {
      return getValue(groupId, id)?.toString()
    },
    useTimestampValue(groupId: string, id: string): dayjs.Dayjs | undefined {
      const timestampStr = getValue(groupId, id)?.toString()
      if (timestampStr === undefined) {
        return undefined
      }
      return dayjs(timestampStr)
    },
    useNumberValue(groupId: string, id: string): number | undefined {
      return getValue(groupId, id)?.toNumber()
    },
    useBoolValue(groupId: string, id: string): boolean | undefined {
      return getValue(groupId, id)?.toBool()
    },
    useEnumValue<T>(groupId: string, id: string): T | undefined {
      return getValue(groupId, id)?.toEnum<T>()
    },
  }
}

export function newMetrics(onError: (error: EdgeError) => void): Metrics {
  return newMetricsWithValues({}, onError, undefined)
}
