import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import LocationIcon24x24 from "../../atoms/icons/LocationIcon24x24"
import MultiStateButton from "../../atoms/buttons/MultiStateButton"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import ButtonRow from "../../molecules/card/ButtonRow"
import { Latitude, Longitude } from "../../../utils/coordinates"
import EditableCoordinateField from "../../molecules/inputs/EditableSettingsCoordinateField"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import { COORDINATE_DEGREE_DECIMAL_COUNT } from "../../constants"

const POSITION_CARD_TOOLTIP = `
It is recommended to use automatic position. Using manual position is not 
recommended while moving, as it may cause the antenna to be pointed in 
the wrong direction. The number of connected satellites is an indication 
for how reliable the coordinates are. A higher number is better, 3 is minimum 
to receive location, 14 is max.
`

const OPTIONS = ["Automatic", "Manual"]

const PositionCard: React.FunctionComponent<{ latitude?: Latitude; longitude?: Longitude }> = (_props) => {
  const [position, setPosition] = React.useState<string>(/*OPTIONS[0]*/)

  const { metrics } = useEdgeApi()

  const latitude_ = metrics.useAntennaPositionLat()
  const longitude_ = metrics.useAntennaPositionLon()

  // TODO: Where to get direction?
  const latitude: Latitude | undefined =
    latitude_ !== undefined ? { degrees: latitude_, direction: "north" } : undefined
  const longitude: Longitude | undefined =
    longitude_ !== undefined ? { degrees: longitude_, direction: "east" } : undefined

  const latitudeString = latitude !== undefined ? latitude.degrees.toFixed(COORDINATE_DEGREE_DECIMAL_COUNT) : undefined
  const longitudeString =
    longitude !== undefined ? longitude.degrees.toFixed(COORDINATE_DEGREE_DECIMAL_COUNT) : undefined

  return (
    <SettingsOverviewCard title="Position" icon={<LocationIcon24x24 />} tooltip={POSITION_CARD_TOOLTIP}>
      <ButtonRow
        label="Coordinates"
        button={<MultiStateButton options={OPTIONS} onClick={setPosition} value={position} />}
      />
      {position === "Manual" && latitude && longitude ? (
        <>
          <EditableCoordinateField
            type="latitude"
            onChange={() => {
              return
            }}
            value={latitude}
          />
          <EditableCoordinateField
            type="longitude"
            onChange={() => {
              return
            }}
            value={longitude}
          />
        </>
      ) : (
        <>
          <ReadOnlyTextField label="Latitude" value={latitudeString} placeholder="--- -" />
          <ReadOnlyTextField label="Longitude" value={longitudeString} placeholder="--- -" />
        </>
      )}
    </SettingsOverviewCard>
  )
}

export default PositionCard
