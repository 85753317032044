import { DeviceMetadataMessage } from "edge-proto/dist/edge/v1/device_metadata"
import * as protoMetricsMetadata from "edge-proto/dist/edge/v1/metrics_metadata"
import * as protoConfigMetadata from "edge-proto/dist/edge/v1/config_metadata"
import { MissingMetadataError, ProtoMissingFieldError } from "./Errors"

export interface MetricGroupMetadata {
  id: string
  values: MetricValueMetadata[]
  value(id: string): MetricValueMetadata | undefined
}

// Immutable interface containg the metadata about the device
export interface DeviceMetadata {
  metricGroup(groupId: string): MetricGroupMetadata | MissingMetadataError
  configValue(id: string): ConfigValueMetadata | undefined | MissingMetadataError
}

// A validator that checks that the string value of a metric value
// is one of the defined enums.
export type EnumMetricValidator = {
  isValid(value: string): boolean
  expectedValues: string[]
} & { $case: "enum" }

export type NoMetricValidator = { $case: "noValidator" }

export type MetricValidator = EnumMetricValidator | NoMetricValidator

export interface MetricValueMetadata {
  id: string
  fullId: string
  index: number
  validator: MetricValidator
}

type BaseConfigValueMetadata = {
  id: string
  readableName: string
}

export type StringConfigValueMetadata = BaseConfigValueMetadata & {
  type: "string"
}

export type EnumConfigValueMetadata = BaseConfigValueMetadata & {
  type: "enum"
  readableEnumValue(value: string): string
  isValid(value: string): boolean
  choices: string[]
}
export type IntConfigValueMetadata = BaseConfigValueMetadata & {
  type: "int"
}
export type UintConfigValueMetadata = BaseConfigValueMetadata & {
  type: "uint"
}

export type FloatConfigValueMetadata = BaseConfigValueMetadata & {
  type: "float"
}
export type BoolConfigValueMetadata = BaseConfigValueMetadata & {
  type: "bool"
}

export type NumberConfigValueMetadata = IntConfigValueMetadata | UintConfigValueMetadata | FloatConfigValueMetadata

export type ConfigValueMetadata =
  | StringConfigValueMetadata
  | EnumConfigValueMetadata
  | NumberConfigValueMetadata
  | BoolConfigValueMetadata

function metricValidator(metric: protoMetricsMetadata.MetricMetadata): MetricValidator {
  const metricType = metric.type

  if (!metricType) {
    return { $case: "noValidator" }
  }

  switch (metricType.$case) {
    case "choices":
      return {
        $case: "enum",
        isValid(value: string): boolean {
          return metricType.choices.choices.includes(value)
        },
        expectedValues: metricType.choices.choices,
      }
    default:
      return {
        $case: "noValidator",
      }
  }
}

function fromProtoMetricValueMetadata(
  metricGroupId: string,
  metric: protoMetricsMetadata.MetricMetadata,
  index: number,
): MetricValueMetadata {
  return { ...metric, ...{ fullId: `${metricGroupId}.${metric}`, index, validator: metricValidator(metric) } }
}

export function parseMetadata(message: DeviceMetadataMessage): DeviceMetadata | ProtoMissingFieldError {
  const metadata = message.metadata
  if (metadata === undefined) {
    return new ProtoMissingFieldError("DeviceMetadataMessage", "Metadata")
  }

  const metricsMetadata = metadata.metricsMetadata
  if (metricsMetadata === undefined) {
    return new ProtoMissingFieldError("DeviceMetadataMessage.Metadata", "MetricsMetadata")
  }

  const idToMetricGroup: { [key: string]: protoMetricsMetadata.MetricGroupMetadata } =
    metricsMetadata.metricGroups.reduce((obj, metricGroup) => {
      return { ...obj, ...{ [metricGroup.id]: metricGroup } }
    }, {})

  const configMetadata = metadata.configValues
  if (configMetadata == undefined) {
    return new ProtoMissingFieldError("DeviceMetadataMessage.Metadata", "ConfigMetadata")
  }

  const configValues = configMetadata.configValuesMetadata

  const idToConfigValue: { [key: string]: protoConfigMetadata.ConfigValueMetadata } = configValues.reduce(
    (obj, configValue) => {
      return { ...obj, ...{ [configValue.id]: configValue } }
    },
    {},
  )

  return {
    metricGroup(groupId: string): MetricGroupMetadata | MissingMetadataError {
      const metricGroup = idToMetricGroup[groupId]
      if (metricGroup === undefined) {
        return new MissingMetadataError(`Metric group ${groupId} is missing from metadata`)
      }
      const values = metricGroup.metrics.map((metric, index) =>
        fromProtoMetricValueMetadata(metricGroup.id, metric, index),
      )
      const idToValue: { [key: string]: MetricValueMetadata } = values.reduce(
        (obj, metric) => ({ ...obj, ...{ [metric.id]: metric } }),
        {},
      )
      return {
        id: groupId,
        values: values,
        value(id: string): MetricValueMetadata | undefined {
          return idToValue[id]
        },
      }
    },
    configValue(id: string): ConfigValueMetadata | undefined | MissingMetadataError {
      const configValue = idToConfigValue[id]
      if (configValue === undefined) {
        return new MissingMetadataError(`Config value with id ${id} is missing from metadata`)
      }

      const base = { id, readableName: configValue.readableName }
      const configType = configValue.type

      switch (configType?.$case) {
        case "intConfig":
          return { ...base, type: "int" }
        case "enumConfig":
          return {
            ...base,
            type: "enum",
            readableEnumValue(value: string): string {
              return configType.enumConfig.choices.find((choice) => choice.value == value)?.readableName ?? value
            },
            isValid(value: string): boolean {
              return configType.enumConfig.choices.map((value) => value.value).includes(value)
            },
            choices: configType.enumConfig.choices.map((value) => value.value),
          }
        case "uintConfig":
          return { ...base, type: "uint" }
        case "boolConfig":
          return { ...base, type: "bool" }
        case "floatConfig":
          return { ...base, type: "float" }
        case "stringConfig":
          return { ...base, type: "string" }
        case undefined:
          return undefined
      }
    },
  }
}
