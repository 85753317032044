// Small wrapper around the native WebSocket type.
// Its main use is to simplify reconnecting
// on any connection issues.
export class Ws {
  private websocket: WebSocket
  private url: string
  private onMessage: (ws: Ws, ev: MessageEvent) => void
  private onOpen: (ws: Ws, ev: Event) => void
  private onClose: (ws: Ws, ev: Event) => void
  private onError: (ws: Ws, ev: Event) => void
  private isClosed: boolean

  constructor(
    url: string,
    onMessage: (ws: Ws, ev: MessageEvent) => void,
    onOpen: (ws: Ws, ev: Event) => void,
    onClose: (ws: Ws, ev: Event) => void,
    onError: (ws: Ws, ev: Event) => void,
  ) {
    this.url = url
    this.onMessage = onMessage
    this.onOpen = onOpen
    this.onClose = onClose
    this.onError = onError
    this.websocket = this.newWebsocket()
    this.isClosed = false
  }

  send(data: Uint8Array): void {
    this.websocket.send(data)
  }

  close(): void {
    this.isClosed = true
    this.websocket.close()
  }

  reconnect(): void {
    if (this.isClosed) {
      return
    }
    this.websocket = this.newWebsocket()
  }

  private newWebsocket(): WebSocket {
    const ws = new WebSocket(this.url)
    ws.binaryType = "arraybuffer"
    ws.onmessage = (ev: MessageEvent) => {
      this.onMessage(this, ev)
    }
    ws.onopen = (ev: Event) => {
      this.onOpen(this, ev)
    }
    ws.onclose = (ev: Event) => {
      this.onClose(this, ev)
    }
    ws.onerror = (ev: Event) => {
      this.onError(this, ev)
    }
    return ws
  }
}
