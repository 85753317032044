import * as React from "react"
import { EnumEditor } from "../../../edge/editor"
import ButtonRow from "../card/ButtonRow"
import MultiStateButton from "../../atoms/buttons/MultiStateButton"

interface Props<T> {
  label: string
  editor?: EnumEditor<T>
}

const ButtonRowEditor = <T extends string>({ editor, label }: Props<T>) => {
  const set = (value: string) => editor?.setValue(value as T)

  const display = (value: string) => editor?.display(value as T) ?? ""

  return (
    <ButtonRow
      label={label}
      button={
        <MultiStateButton onClick={set} options={editor?.choices() ?? []} value={editor?.value} display={display} />
      }
    />
  )
}

export default ButtonRowEditor
