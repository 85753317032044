import { useState, useCallback } from "react"

type Dimension = "height" | "width"

export const useMeasure = (dimension: Dimension): [(node: HTMLDivElement) => void, number] => {
  const [size, setSize] = useState<number>(0)

  const ref = useCallback((node: HTMLDivElement) => {
    if (node === null) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSize(entry.target.getBoundingClientRect()[dimension])
      }
    })

    resizeObserver.observe(node)

    return () => {
      resizeObserver.unobserve(node)
    }
  }, [])

  return [ref, size]
}
