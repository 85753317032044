import * as React from "react"
import Dropdown, { DropdownItem } from "./Dropdown"
import { EnumEditor } from "../../../edge/editor"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"

interface Props<T> {
  label: string
  editor?: EnumEditor<T>
}

const DropdownEditor = <T extends string>({ editor, label }: Props<T>) => {
  const toItem = (value: T): DropdownItem<T, string> => {
    const displayValue = editor?.display(value) ?? value
    return {
      id: value,
      value: displayValue,
      title: displayValue,
    }
  }

  const items = editor?.choices().map(toItem) ?? []

  return (
    <SettingsFieldWithLabel label={label}>
      <Dropdown
        items={items}
        onClickItem={(item) => {
          editor?.setValue(item.id)
        }}
        selectedItem={editor ? toItem(editor.value) : undefined}
        disabled={editor === undefined}
      />
    </SettingsFieldWithLabel>
  )
}

export default DropdownEditor
