import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import SatelliteIcon from "../../atoms/icons/SatelliteIcon"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import { HorizontalSpacer } from "../../atoms/spacers"
import { Col, Row, Visible } from "react-grid-system"
import { AntennaOrientation, Frequency, SignalStrengthWithZero } from "../../../types"
import OrientationField from "../../molecules/card/OrientationField"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import BeamSwitchIcon from "../../atoms/icons/BeamSwitchIcon"
import BeamSwitchModal from "../modals/BeamSwitchModal"
import { SettingsFieldDivider } from "../../atoms/card/SettingsField"
import SignalStrengthChart from "../../molecules/charts/SignalStrengthChart"
import CNChart from "../../molecules/charts/CNChart"
import { DeepPartial } from "edge-proto/dist/edge/v1/metrics"

const SATELLITE_SIGNAL_TOOLTIP = `
C/N represents the strength of the data signal from the satellite, measured relative to the background noise. 
Beacon represents the strenght of the received beacon signal from the satellite, used to track the satellite. 
The current orientation indicates where the antenna is pointing. 
This can be compared to the target orientation, which is where the antenna should be pointing.
`

interface SatelliteSignalCardProps {
  currentOrientation?: DeepPartial<AntennaOrientation>
  targetOrientation?: DeepPartial<AntennaOrientation>
  signalStrength?: SignalStrengthWithZero
  beaconStrength?: number
  cOverN?: number
  highestCN?: number
  rf?: Frequency
  lo?: Frequency
  bandwidth?: Frequency
  beamId?: string
}

const SatelliteSignalCard: React.FunctionComponent<SatelliteSignalCardProps> = ({
  currentOrientation,
  targetOrientation,
  cOverN,
  signalStrength,
  rf,
  lo,
  bandwidth,
  beamId,
}) => {
  const [beamSwitchModalOpen, setBeamSwitchModalOpen] = React.useState(false)

  return (
    <SettingsOverviewCard title="Satellite signal" icon={<SatelliteIcon />} tooltip={SATELLITE_SIGNAL_TOOLTIP}>
      <Row justify="between" align="center">
        <SignalStrengthChart signalStrength={signalStrength} />
        <CNChart cOverN={cOverN} />
        {/* TODO unhide after mvp */}
        {/* <BeaconGraph beaconStrength={beaconStrength} /> */}
      </Row>
      <HorizontalSpacer spacing={2} />
      <Row justify="between">
        <Col>
          <OrientationField title="Current orientation" orientation={currentOrientation} />
          <Visible xs sm>
            <HorizontalSpacer spacing={2} />
          </Visible>
        </Col>
        <Col>
          <OrientationField title="Target orientation" orientation={targetOrientation} />
          <Visible xs sm>
            <HorizontalSpacer spacing={2} />
          </Visible>
        </Col>
      </Row>
      <HorizontalSpacer spacing={2} />
      <Row justify="between">
        <Col sm={12} md={6}>
          <ReadOnlyTextField
            label="Beam ID"
            value={beamId}
            secondValue={beamId}
            placeholder="----------"
            secondPlaceholder="---"
            textAlign="right"
          />
          <ReadOnlyTextField
            label="RF frequency"
            value={rf?.rx.toString()}
            secondValue={rf?.tx.toString()}
            placeholder="Rx: -- MHz"
            secondPlaceholder="Tx: -- MHz"
            textAlign="right"
          />
        </Col>
        <Visible xs sm>
          <SettingsFieldDivider />
        </Visible>
        <Col sm={12} md={6}>
          <ReadOnlyTextField
            label="Bandwidth"
            value={bandwidth?.rx.toString()}
            secondValue={bandwidth?.tx.toString()}
            placeholder="Rx: -- MHz"
            secondPlaceholder="Tx: -- MHz"
            textAlign="right"
          />
          <ReadOnlyTextField
            label="LO frequency"
            value={lo?.rx.toString()}
            secondValue={lo?.tx.toString()}
            placeholder="Rx: -- GHz"
            secondPlaceholder="Tx: -- GHz"
            textAlign="right"
          />
        </Col>
      </Row>
      <HorizontalSpacer spacing={2} />
      <PrimaryButton
        title="Beam switch"
        iconLeft={<BeamSwitchIcon />}
        fullWidth
        onClick={() => setBeamSwitchModalOpen(true)}
      />
      <BeamSwitchModal open={beamSwitchModalOpen} onClose={() => setBeamSwitchModalOpen(false)} />
    </SettingsOverviewCard>
  )
}

export default SatelliteSignalCard
