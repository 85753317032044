import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type LoginState = "login" | "logout" | "logout_unack"

export type CheckAuth = { appStart: boolean }

// user_logout: The end-user initiated the logout by clicking on the logout button in the UI
// session_expired: The login session expired
// session_expired_app_start: The login session has expired when the user accessed the application
// api_anauthorized: If the edge api returns a unauthorized (401) status code
export type LogoutReason = "user_logout" | "session_expired" | "session_expired_app_start" | "api_unauthorized"

// Note that it might not be safe to change these variable names since
// they are used by redux-persist in the marshalling of the state.
interface UserState {
  username?: string
  sessionExpires?: number // Unix timestamp?
  loginState?: LoginState // undefined should be equal to the logout state
  logoutReason?: LogoutReason
}

const initialState: UserState = {
  username: undefined,
  sessionExpires: undefined,
  loginState: undefined,
}

export function isLoggedIn(userState: UserState): boolean {
  return userState.loginState === "login"
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ username: string; sessionExpires: number }>) => {
      state.loginState = "login"
      state.username = action.payload.username
      state.sessionExpires = action.payload.sessionExpires
    },
    clearUser: (state, action: PayloadAction<LogoutReason>) => {
      if (!isLoggedIn(state)) {
        return
      }
      state.username = undefined
      state.sessionExpires = undefined
      state.logoutReason = action.payload
      switch (action.payload) {
        case "session_expired":
          state.loginState = "logout_unack"
          break
        case "api_unauthorized":
          state.loginState = "logout_unack"
          break
        default:
          state.loginState = "logout"
      }
    },
    // Check auth doesn't manipulate any state but
    // is used by middleware. It's used to check if the
    // session is still valid.
    checkAuth: (_state, _payload: PayloadAction<CheckAuth>) => {
      return
    },
    ackLogout: (state) => {
      if (state.loginState !== "logout_unack") {
        return
      }
      state.loginState = "logout"
      state.logoutReason = undefined
    },
  },
})

export const { setUser, clearUser, checkAuth, ackLogout } = userSlice.actions

export default userSlice.reducer
