// Simplified wrapper over protobuf config to simplify getting and settings config values

import * as configProto from "edge-proto/dist/edge/v1/config"
import {
  DeviceMetadata,
  ConfigValueMetadata,
  NumberConfigValueMetadata,
  EnumConfigValueMetadata,
  BoolConfigValueMetadata,
  StringConfigValueMetadata,
} from "./DeviceMetadata"
import {
  EdgeError,
  MetadataNotAvailableError,
  MissingMetadataError,
  ProtoMissingFieldError,
  UnexpectedConfigTypeError,
  UnexpectedConfigValueError,
} from "./Errors"

// In the best of worlds this type would be exported directly by the generated
// protobuf typescript files. But it's not, so we just copy it here.
//
// NB: The corresponding type in protobuf allows undefined here, but we don't
// for extra type safety.
type ProtoValueType =
  | ({
      int32Value?: number | undefined
    } & {
      $case: "int32Value"
    })
  | ({
      uint32Value?: number | undefined
    } & {
      $case: "uint32Value"
    })
  | ({
      stringValue?: string | undefined
    } & {
      $case: "stringValue"
    })
  | ({
      floatValue?: number | undefined
    } & {
      $case: "floatValue"
    })
  | ({
      boolValue?: boolean | undefined
    } & {
      $case: "boolValue"
    })

interface ProgressValue {
  value: configProto.ConfigValue
  progress: number | undefined
}

// Sum type of all javascript types that are used in the applicatio
// layer for handling of config values.
export type JsConfigValue = string | number | boolean

export type ConfigValueWithExtraData<T> = {
  configValue: configProto.ConfigValue
  extraData: T
}

/**
 * Interface which contains the state of the config values.
 */
interface ValueState<T> {
  // The value of the device
  value: configProto.ConfigValue
  // Represent the value that is changed by the end-user. If the
  // value has not been changed during the end-user session, this
  // value is undefined. It's also undefined whenever the value
  // is equal the the device value. The value is stored in order to
  // get a diff of the device's config and the configuration that
  // has been applied in the UI.
  userChangedValue: ConfigValueWithExtraData<T> | undefined
  // Contains a config value and a progress value (a number between
  // 0 - 99), which is used to indicate progression to the end-user
  // while a new value is being applied.
  // Normally, progressValue is null. Only when a value is in a progress
  // state the progressValue member is not null. The old value is stored
  // while being in a progress state in order to revert to previous
  // state in case of any error.
  progressValue: ProgressValue | undefined
}

// In order to not send redundant values to the backend +
// going back to previous values.
type ConfigValuesContainer<T> = { [key: string]: ValueState<T> }

export interface ConfigValue {
  toString(): [string, string, StringConfigValueMetadata] | UnexpectedConfigValueError
  toBool(): [boolean, boolean, BoolConfigValueMetadata] | UnexpectedConfigValueError
  toNumber(): [number, number, NumberConfigValueMetadata] | UnexpectedConfigValueError
  toEnum<T>(): [T, T, EnumConfigValueMetadata] | UnexpectedConfigValueError
}

function protoValueToString(value?: ProtoValueType): string {
  const missing = "none"
  if (value === undefined) {
    return missing
  }
  switch (value.$case) {
    case "boolValue": {
      if (value.boolValue === undefined) {
        return missing
      }
      return value.boolValue ? "true" : "false"
    }
    case "int32Value": {
      return value.int32Value?.toString() ?? missing
    }
    case "floatValue": {
      return value.floatValue?.toString() ?? missing
    }
    case "uint32Value": {
      return value.uint32Value?.toString() ?? missing
    }
    case "stringValue": {
      return value.stringValue?.toString() ?? missing
    }
  }
}

function compareConfigValues(c1: configProto.ConfigValue, c2: configProto.ConfigValue): boolean {
  const compareArray = (a1: Uint8Array, a2: Uint8Array): boolean => {
    if (a1.length !== a2.length) {
      return false
    }
    return a1.every((value, index) => value === a2[index])
  }

  const toArray = (configValue: configProto.ConfigValue): Uint8Array => {
    return configProto.ConfigValue.encode(configValue).finish()
  }

  return compareArray(toArray(c1), toArray(c2))
}

export function configValue(
  configValueMetadata: ConfigValueMetadata,
  protoValue: configProto.ConfigValue,
  protoValueDevice: configProto.ConfigValue,
): ConfigValue {
  const value = protoValue.value
  const deviceValue = protoValueDevice.value

  const error = (msg: string): UnexpectedConfigValueError => {
    return new UnexpectedConfigValueError(
      `For config ${protoValue.id} with value ${protoValueToString(protoValue.value)}: ${msg}`,
    )
  }

  const missingValueError = () => error("Config value is missing in proto definition")

  const unexpectedTypeError = (expectedType: string) =>
    error(`Got unexpected type ${value?.$case}, but expected a ${expectedType}`)

  const unexpectedMetadataType = (expectedType: string) =>
    error(`Got unexpected metadata type ${configValueMetadata.type}, but expected a ${expectedType}`)

  return {
    toString(): [string, string, StringConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      if (value.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      if (deviceValue.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      if (configValueMetadata.type !== "string") {
        return unexpectedMetadataType("string")
      }
      return [value.stringValue, deviceValue.stringValue, configValueMetadata]
    },
    toBool(): [boolean, boolean, BoolConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      if (value.$case !== "boolValue") {
        return unexpectedTypeError("bool")
      }
      if (deviceValue.$case !== "boolValue") {
        return unexpectedTypeError("bool")
      }
      if (configValueMetadata.type !== "bool") {
        return unexpectedMetadataType("bool")
      }
      return [value.boolValue, deviceValue.boolValue, configValueMetadata]
    },
    toNumber(): [number, number, NumberConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      switch (value.$case) {
        case "floatValue": {
          if (configValueMetadata.type !== "float") {
            return unexpectedMetadataType("float")
          }
          if (deviceValue.$case !== "floatValue") {
            return unexpectedMetadataType("float")
          }
          return [value.floatValue, deviceValue.floatValue, configValueMetadata]
        }
        case "int32Value": {
          if (configValueMetadata.type !== "int") {
            return unexpectedMetadataType("int")
          }
          if (deviceValue.$case !== "int32Value") {
            return unexpectedMetadataType("int")
          }
          return [value.int32Value, deviceValue.int32Value, configValueMetadata]
        }
        case "uint32Value": {
          if (configValueMetadata.type !== "uint") {
            return unexpectedMetadataType("uint")
          }
          if (deviceValue.$case !== "uint32Value") {
            return unexpectedMetadataType("uint")
          }
          return [value.uint32Value, deviceValue.uint32Value, configValueMetadata]
        }
        default:
          return unexpectedTypeError("int | uint | float")
      }
    },
    toEnum<T>(): [T, T, EnumConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      if (value.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      if (deviceValue.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      switch (configValueMetadata.type) {
        case "enum":
          if (!configValueMetadata.isValid(value.stringValue)) {
            return error(`Expected enum value to be one of ${configValueMetadata.choices}`)
          }
          return [value.stringValue as T, deviceValue.stringValue as T, configValueMetadata]
        default:
          return unexpectedTypeError("enum")
      }
    },
  }
}

export type ReadConfigState = { state: "read" }
export type ReadFailedConfigState = { state: "readFailed" }
export type NormalConfigState = { state: "normal" }
export type DiscardConfigState<T> = { state: "discard"; values: ConfigValueWithExtraData<T>[] }
export type PushConfigState<T> = {
  state: "push"
  appliedValues: ConfigValueWithExtraData<T>[]
  values: ConfigValueWithExtraData<T>[]
}
export type PushFailedConfigState<T> = {
  state: "pushFailed"
  failedValues: ConfigValueWithExtraData<T>[]
  successfulValues: ConfigValueWithExtraData<T>[]
}

/**
 * TODO: Documentation
 */
export type ConfigState<T> =
  | ReadConfigState
  | ReadFailedConfigState
  | NormalConfigState
  | DiscardConfigState<T>
  | PushConfigState<T>
  | PushFailedConfigState<T>

/**
 * Data model containing config values.
 */
export interface Config<T> {
  /**
   * Returns a config value given a config ID
   *
   * @returns the config value. The value
   * is undefined if the config value hasn't been received from the
   * edge API yet or the metadata isn't available yet.
   */
  value(id: string): ConfigValue | UnexpectedConfigValueError | undefined
  valueMetadata(id: string): ConfigValueMetadata | MissingMetadataError | undefined
  /**
   * Updates a config value given an ID and the new value. The function should be called
   * when the end-user changes the value of config values.
   *
   * @param id of the config value
   * @param value the javascript value used to update the config value
   * @param extraData the generic parameter is used to save arbitrary data with
   *        the updated value. The extraData value is returned by other
   *        functions such as the changedValues() function. The extra data
   *        should be used in cases where it makes sense to save UI data
   *        related to the config value. For example, the WebGUI application
   *        sets the generic value as a string and stores the "widget origin"
   *        of the data, so the set of widget that has altered config values
   *        can be displayed in the banner used to push the config values to
   *        the device.
   */
  setValue(id: string, value: JsConfigValue, extraData: T): Config<T> | MetadataNotAvailableError
  /**
   * All user changed values. This is the values that differs from the UI and the
   * device's config values.
   */
  changedValues(): ConfigValueWithExtraData<T>[]
  /**
   * If the user has changed any values.
   */
  hasChangedValues(): boolean
  /**
   * Discard all changed values.
   */
  discard(): Config<T>
  /**
   * Create a protobuf message that can be sent to the edge server to the
   * /api/ws/config?update endpoint in order to apply all changed config values.
   * Note that beginPush() should be called when this function is called.
   */
  pushChangedConfigProtoMessage(): configProto.ClientConfigMessage
  /**
   * Injects metadata so that config values can be parsed.
   */
  setMetadata(metadata: DeviceMetadata): Config<T>
  /**
   * Update config given a config message from the edge server.
   */
  update(metricMessage: configProto.ConfigMessage): Config<T> | ProtoMissingFieldError
  state: ConfigState<T>
  /**
   * Set the config to a pushing state. This function should be called
   * right before the config is updated by sending the update request to
   * the device.
   */
  beginPush(): Config<T>
  /**
   * The the config to a normal state or to a "pushFailed" state.
   */
  endPush(): Config<T>

  /**
   * Transititions the state to a "normal" or a "readFailed" state.
   * This function must be called regardless of the outcome after
   * applying the values.
   */
  endRead(): Config<T>
}

interface ParsedDeviceConfigValue {
  state: configProto.ConfigState
  value: configProto.ConfigValue
}

function parseProtoConfigValues(
  configValues: configProto.DeviceConfigValue[],
): ParsedDeviceConfigValue[] | ProtoMissingFieldError {
  return configValues.reduce(
    (obj, value) => {
      if (obj instanceof ProtoMissingFieldError) {
        return obj
      }
      if (value.state === undefined) {
        return new ProtoMissingFieldError("DeviceConfigValue", "state")
      }
      if (value.value === undefined) {
        return new ProtoMissingFieldError("DeviceConfigValue", "value")
      }
      return [...obj, { state: value.state, value: value.value }]
    },
    <ParsedDeviceConfigValue[] | ProtoMissingFieldError>[],
  )
}

function jsValueToProtoValueConfig(
  id: string,
  value: JsConfigValue,
  metadata: ConfigValueMetadata,
): configProto.ConfigValue | UnexpectedConfigTypeError | MissingMetadataError {
  const valueF = (): ProtoValueType | UnexpectedConfigTypeError | MissingMetadataError => {
    const errorType = (expectedType: string, gotType: string): UnexpectedConfigTypeError =>
      new UnexpectedConfigTypeError(`Unexpected type, expected ${expectedType} but got ${gotType} for id ${id}`)

    switch (metadata.type) {
      case "int": {
        if (typeof value !== "number") {
          return errorType("number", typeof value)
        }
        return {
          $case: "int32Value",
          int32Value: value,
        }
      }
      case "uint": {
        if (typeof value !== "number") {
          return errorType("number", typeof value)
        }
        return {
          $case: "uint32Value",
          uint32Value: value,
        }
      }
      case "string": {
        if (typeof value !== "string") {
          return errorType("string", typeof value)
        }
        return {
          $case: "stringValue",
          stringValue: value,
        }
      }
      case "enum": {
        if (typeof value !== "string") {
          return errorType("string", typeof value)
        }
        return {
          $case: "stringValue",
          stringValue: value,
        }
      }
      case "float": {
        if (typeof value !== "number") {
          return errorType("number", typeof value)
        }
        return {
          $case: "floatValue",
          floatValue: value,
        }
      }
      case "bool": {
        if (typeof value !== "boolean") {
          return errorType("boolean", typeof value)
        }
        return {
          $case: "boolValue",
          boolValue: value,
        }
      }
      case undefined: {
        return new ProtoMissingFieldError("ConfigValueMetadata", "type")
      }
    }
  }

  const protoValue = valueF()

  if (protoValue instanceof EdgeError) {
    return protoValue
  }

  return configProto.ConfigValue.create({
    id,
    value: protoValue,
  })
}

function applyUserValue<T>(
  prevValues: ConfigValuesContainer<T>,
  value: configProto.ConfigValue,
  extraData: T,
): ConfigValuesContainer<T> | UnexpectedConfigValueError {
  const prevValue = prevValues[value.id]
  if (prevValue === undefined) {
    return new UnexpectedConfigValueError(
      `Unable to apply a config value ${value.id} since such a value isn't available in the device`,
    )
  }

  const newUserValue = compareConfigValues(prevValue.value, value) ? undefined : { configValue: value, extraData }

  return { ...prevValues, ...{ [value.id]: { ...prevValue, ...{ userChangedValue: newUserValue } } } }
}

function applyDeviceValues<T>(
  prevValues: ConfigValuesContainer<T>,
  newValues: ParsedDeviceConfigValue[],
): ConfigValuesContainer<T> | ProtoMissingFieldError {
  return newValues.reduce(
    (newValues, value) => {
      if (newValues instanceof ProtoMissingFieldError) {
        return newValues
      }
      const id = value.value.id
      const prevValue = newValues[id] ?? { value: undefined, progressValue: undefined, userChangedValue: undefined }
      const state = value.state.state
      if (state === undefined) {
        return new ProtoMissingFieldError("DeviceConfigValue", "state")
      }
      switch (state.$case) {
        case "commited": {
          return {
            ...newValues,
            ...{
              [id]: { ...prevValue, ...{ value: value.value, progressValue: undefined, userChangedValue: undefined } },
            },
          }
        }
        case "pending": {
          let progress = undefined
          const msgProgress = state.pending.pendingProgress
          if (msgProgress !== -1) {
            progress = state.pending.pendingProgress
          }
          return {
            ...newValues,
            ...{ [id]: { ...prevValue, ...{ progressValue: { value: value.value, progress: progress } } } },
          }
        }
        case "fail": {
          // TODO: Handle error and make it show in UI
          return { ...newValues, ...{ [id]: { ...prevValue, ...{ progressValue: undefined } } } }
        }
      }
    },
    <ConfigValuesContainer<T> | ProtoMissingFieldError>prevValues,
  )
}

function newConfigWithValues<T>(
  values: ConfigValuesContainer<T>,
  state: ConfigState<T>,
  metadata?: DeviceMetadata,
): Config<T> {
  const noop = () => newConfigWithValues(values, state, metadata)

  const valueMetadata = (id: string): ConfigValueMetadata | MissingMetadataError | undefined => {
    if (metadata === undefined) {
      return undefined
    }
    const configValueMetadata = metadata.configValue(id)
    if (configValueMetadata === undefined) {
      return new UnexpectedConfigValueError(`Unexpected config value with id ${id}`)
    }
    if (configValueMetadata instanceof MissingMetadataError) {
      return configValueMetadata
    }
    return configValueMetadata
  }

  const changedValues = (): ConfigValueWithExtraData<T>[] => {
    return Object.values(values)
      .map((value) => value.userChangedValue)
      .filter((value): value is ConfigValueWithExtraData<T> => !!value)
  }

  return {
    value(id: string): ConfigValue | undefined | UnexpectedConfigValueError | ProtoMissingFieldError {
      const value = values[id]
      if (value === undefined) {
        return undefined
      }
      const protoValue = value.userChangedValue?.configValue ?? value.value
      if (protoValue === undefined) {
        return undefined
      }
      const configValueMetadata = valueMetadata(id)
      if (configValueMetadata === undefined) {
        return undefined
      }
      if (configValueMetadata instanceof EdgeError) {
        return configValueMetadata
      }
      return configValue(configValueMetadata, protoValue, value.value)
    },
    setValue(
      id: string,
      value: JsConfigValue,
      extraData: T,
    ): Config<T> | UnexpectedConfigTypeError | MetadataNotAvailableError | UnexpectedConfigValueError {
      const configValueMetadata = valueMetadata(id)
      if (configValueMetadata === undefined) {
        return new MetadataNotAvailableError()
      }
      if (configValueMetadata instanceof MissingMetadataError) {
        return configValueMetadata
      }
      const protoValue = jsValueToProtoValueConfig(id, value, configValueMetadata)
      if (protoValue instanceof EdgeError) {
        return protoValue
      }
      const newValues = applyUserValue(values, protoValue, extraData)
      if (newValues instanceof UnexpectedConfigValueError) {
        return newValues
      }
      return newConfigWithValues(newValues, { state: "normal" }, metadata)
    },
    changedValues,
    hasChangedValues(): boolean {
      return Object.values(values).find((value) => value.userChangedValue !== undefined) !== undefined
    },
    discard(): Config<T> {
      const newValues = Object.values(values).reduce((newValues, value) => {
        return { ...newValues, ...{ [value.value.id]: { ...value, ...{ userChangedValue: undefined } } } }
      }, {})
      return newConfigWithValues(newValues, { state: "discard", values: changedValues() }, metadata)
    },
    pushChangedConfigProtoMessage(): configProto.ClientConfigMessage {
      return configProto.ClientConfigMessage.create({
        updateRequest: {
          configSet: {
            configValues: changedValues().map((value) => value.configValue),
          },
        },
      })
    },
    valueMetadata,
    setMetadata(metadata: DeviceMetadata): Config<T> {
      return newConfigWithValues(values, state, metadata)
    },
    update(metricMessage: configProto.ConfigMessage): Config<T> | ProtoMissingFieldError {
      const message = metricMessage.message
      if (message === undefined) {
        return new ProtoMissingFieldError("ConfigMessage", "message")
      }
      switch (message.$case) {
        case "updateResponse":
          return noop()
        case "updateStream": {
          const configSet = message.updateStream.configSet
          if (configSet === undefined) {
            return new ProtoMissingFieldError("UpdateStream", "configSet")
          }
          const configValues = parseProtoConfigValues(configSet.configValues)
          if (configValues instanceof ProtoMissingFieldError) {
            return configValues
          }
          const newValues = applyDeviceValues(values, configValues)
          if (newValues instanceof ProtoMissingFieldError) {
            return newValues
          }

          let newState = state
          if (state.state == "push") {
            newState = {
              ...state,
              appliedValues: [
                ...state.appliedValues,
                ...state.values.filter((value) =>
                  configValues
                    .filter((value) => value.state.state?.$case === "commited")
                    .map((value) => value.value.id)
                    .includes(value.configValue.id),
                ),
              ],
            }
          }

          return newConfigWithValues(newValues, newState, metadata)
        }
        case "error":
          // TODO: Handle error
          return noop()
      }
    },
    state,
    beginPush(): Config<T> {
      const state: PushConfigState<T> = { state: "push", appliedValues: [], values: changedValues() }
      return newConfigWithValues(values, state, metadata)
    },
    endPush(): Config<T> {
      if (state.state == "push" && state.values.length != state.appliedValues.length) {
        return newConfigWithValues(
          values,
          {
            state: "pushFailed",
            failedValues: state.values.filter(
              (value) =>
                state.appliedValues.find((appliedValue) => appliedValue.configValue.id === value.configValue.id) ===
                undefined,
            ),
            successfulValues: state.appliedValues,
          },
          metadata,
        )
      }
      return newConfigWithValues(values, { state: "normal" }, metadata)
    },
    endRead(): Config<T> {
      if (changedValues().length != 0) {
        return newConfigWithValues(values, { state: "readFailed" }, metadata)
      }
      return newConfigWithValues(values, { state: "normal" }, metadata)
    },
  }
}

export function newConfig<T>(): Config<T> {
  return newConfigWithValues({}, { state: "read" }, undefined)
}
