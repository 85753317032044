import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import WifiIcon24x24 from "../../atoms/icons/WifiIcon24x24"
import ButtonRow from "../../molecules/card/ButtonRow"
import ToggleButton from "../../atoms/inputs/ToggleButton"
import EditableTextField from "../../molecules/inputs/EditableTextField"
import EditablePasswordField from "../../molecules/inputs/EditableSettingsSecretField"
import DropdownEditor from "../../molecules/inputs/DropdownEditor"
import TurnOffWifiModal from "../modals/TurnOffWifiModal"
import { pages } from "../../../utils/pages"
import { useEdgeApi } from "../../../edge/EdgeProvider"

const WifiCard: React.FunctionComponent = () => {
  const { config } = useEdgeApi()

  // not sure if we want this was from the figma design, also there was no SSID field.
  const ssid = config.useWifiSsid("Wifi")
  const password = config.useWifiPassword("Wifi")
  const wifi = config.useWifiEnabled("Wifi")

  const [turnOffWifiModalOpen, setTurnOffWifiModalOpen] = React.useState(false)

  const handleWifiToggle = () => {
    if (wifi?.value) {
      setTurnOffWifiModalOpen(true)
    } else {
      wifi?.setValue(true)
    }
  }

  const handleConfirmWifiOff = () => {
    wifi?.setValue(false)
    setTurnOffWifiModalOpen(false)
  }

  const noset = () => {
    return
  }

  return (
    <>
      <SettingsOverviewCard
        title={pages.overview.sections[3].name}
        data-testid={pages.overview.sections[3].name}
        icon={<WifiIcon24x24 />}
      >
        <ButtonRow label="Wifi on" button={<ToggleButton active={wifi?.value} onToggle={handleWifiToggle} />} />
        <EditableTextField label="SSID" value={ssid?.value} onChange={ssid?.setValue ?? noset} />
        <EditablePasswordField label="Password" value={password?.value} onChange={password?.setValue ?? noset} />
        <DropdownEditor label="Encryption" editor={config.useWifiSecurity("Wifi")} />
      </SettingsOverviewCard>
      <TurnOffWifiModal
        open={turnOffWifiModalOpen}
        onClose={() => setTurnOffWifiModalOpen(false)}
        onConfirm={handleConfirmWifiOff}
      />
    </>
  )
}

export default WifiCard
