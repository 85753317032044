// Simplified wrapper over protobuf config to simplify getting and settings config values

import * as configProto from "edge-proto/dist/edge/v1/config"
import { Config, ConfigState, ConfigValue, ConfigValueWithExtraData, JsConfigValue } from "./Config"
import {
  BoolConfigValueMetadata,
  ConfigValueMetadata,
  EnumConfigValueMetadata,
  NumberConfigValueMetadata,
  StringConfigValueMetadata,
} from "./DeviceMetadata"
import { BoolEditor, EnumEditor, StringEditor, NumberEditor } from "./editor"
import { EdgeError } from "./Errors"

export interface ConfigValueNoError {
  toString(): [string, string, StringConfigValueMetadata] | undefined
  toNumber(): [number, number, NumberConfigValueMetadata] | undefined
  toBool(): [boolean, boolean, BoolConfigValueMetadata] | undefined
  toEnum<T>(): [T, T, EnumConfigValueMetadata] | undefined
}

/**
 * The UIConfig is an abstraction and simpler interface in UI related code
 * where an config errors doesn't matter, in contrast to the {@link Config}
 * interface which has similar functions but returns errors instead. The
 * implementation hides any errors and calls a callback instead in case of
 * eventual errors. The callback could for example trigger a logging message
 * or display a dialog to the end-user.
 */
export interface UIConfig<T> {
  value(id: string): ConfigValueNoError | undefined
  setValue(id: string, value: JsConfigValue, extraData: T): void
  changedValues(): ConfigValueWithExtraData<T>[]
  hasChangedValues(): boolean
  pushToDevice(): void
  discard(): void
  displayValue(value: configProto.ConfigValue): string
  valueMetadata(id: string): ConfigValueMetadata | undefined

  state(): ConfigState<T>

  useStringValue(id: string, extraData: T): StringEditor | undefined
  useNumberValue(id: string, extraData: T): NumberEditor | undefined
  useBoolValue(id: string, extraData: T): BoolEditor | undefined
  useEnumValue<D extends string>(id: string, extraData: T): EnumEditor<D> | undefined
}

function configValueNoError(value: ConfigValue, onError: (error: EdgeError) => void): ConfigValueNoError {
  const handleError = <T>(value: T | EdgeError | undefined): T | undefined => {
    if (value instanceof EdgeError) {
      onError(value)
      return undefined
    }
    return value
  }

  return {
    toString(): [string, string, StringConfigValueMetadata] | undefined {
      return handleError(value.toString())
    },
    toNumber(): [number, number, NumberConfigValueMetadata] | undefined {
      return handleError(value.toNumber())
    },
    toBool(): [boolean, boolean, BoolConfigValueMetadata] | undefined {
      return handleError(value.toBool())
    },
    toEnum<T>(): [T, T, EnumConfigValueMetadata] | undefined {
      return handleError(value.toEnum())
    },
  }
}

export function newUIConfig<T>(
  config: Config<T>,
  setConfig: (newConfig: Config<T>) => void,
  pushToDevice: () => void,
  onError: (error: EdgeError) => void,
): UIConfig<T> {
  const getValue = (id: string): ConfigValueNoError | undefined => {
    const value = config.value(id)
    if (value instanceof EdgeError) {
      onError(value)
      return undefined
    }
    if (value === undefined) {
      return undefined
    }
    return configValueNoError(value, onError)
  }

  const setValue = (id: string, value: JsConfigValue, extraData: T): void => {
    const newConfig = config.setValue(id, value, extraData)
    if (newConfig instanceof EdgeError) {
      onError(newConfig)
      return
    }
    setConfig(newConfig)
  }

  const valueMetadata = (id: string): ConfigValueMetadata | undefined => {
    const metadata = config.valueMetadata(id)
    if (metadata instanceof EdgeError) {
      onError(metadata)
      return undefined
    }
    return metadata
  }

  return {
    value: getValue,
    setValue: setValue,
    changedValues(): ConfigValueWithExtraData<T>[] {
      return config.changedValues()
    },
    hasChangedValues(): boolean {
      return config.hasChangedValues()
    },
    pushToDevice(): void {
      pushToDevice()
    },
    discard(): void {
      setConfig(config.discard())
    },
    displayValue(configValue: configProto.ConfigValue): string {
      const metadata = valueMetadata(configValue.id)
      if (metadata === undefined) {
        return ""
      }
      const value = configValue.value
      switch (value?.$case) {
        case "boolValue": {
          return value.boolValue ? "On" : "Off"
        }
        case "int32Value": {
          return value.int32Value.toString()
        }
        case "uint32Value": {
          return value.uint32Value.toString()
        }
        case "floatValue": {
          return value.floatValue.toString()
        }
        case "stringValue": {
          switch (metadata.type) {
            case "enum": {
              return metadata.readableEnumValue(value.stringValue)
            }
          }
          return value.stringValue
        }
        case undefined: {
          return ""
        }
      }
    },
    valueMetadata,
    useStringValue(id: string, extraData: T): StringEditor | undefined {
      const stringValue = getValue(id)?.toString()
      if (stringValue === undefined) {
        return undefined
      }
      const [value, deviceValue, _] = stringValue
      return {
        value,
        deviceValue,
        setValue(value: string): void {
          setValue(id, value, extraData)
        },
        setValueFromString(value: string): void {
          setValue(id, value, extraData)
        },
        validateInput(_value: string): undefined | string {
          return undefined
        },
        display(value: string): string {
          return value
        },
      }
    },
    useNumberValue(id: string, extraData: T): NumberEditor | undefined {
      const numberValue = getValue(id)?.toNumber()
      if (numberValue === undefined) {
        return undefined
      }
      const [value, deviceValue, _] = numberValue
      return {
        value,
        deviceValue,
        setValue(value: number): void {
          setValue(id, value, extraData)
        },
        setValueFromString(value: string): void {
          setValue(id, parseInt(value), extraData)
        },
        validateInput(_value: string): undefined | string {
          return undefined
        },
        display(_value: number): string {
          return value.toString()
        },
      }
    },
    useBoolValue(id: string, extraData: T): BoolEditor | undefined {
      const boolValue = getValue(id)?.toBool()
      if (boolValue === undefined) {
        return undefined
      }
      const [value, deviceValue, _] = boolValue
      return {
        value,
        deviceValue,
        setValue(value: boolean): void {
          setValue(id, value, extraData)
        },
      }
    },
    useEnumValue<D extends string>(id: string, extraData: T): EnumEditor<D> | undefined {
      const enumValue = getValue(id)?.toEnum<D>()
      if (enumValue === undefined) {
        return undefined
      }
      const [value, deviceValue, meta] = enumValue
      return {
        value,
        deviceValue,
        setValue(value: D): void {
          setValue(id, value, extraData)
        },
        display(value: D): string {
          return meta.readableEnumValue(value)
        },
        choices(): D[] {
          return meta.choices as D[]
        },
      }
    },
    state(): ConfigState<T> {
      return config.state
    },
  }
}
