import * as React from "react"
import { Row } from "react-grid-system"
import styled from "styled-components"
import { Spacer } from "../../atoms/spacers"
import { CheckmarkIcon } from "../../atoms/icons/CheckboxIcons"
import H3 from "../../atoms/typography/H3"
import { ArrowDropDownIcon } from "../../atoms/icons/ArrowDropDownIcon"
import { theme } from "../../theme"

export interface DropdownItem<T extends string = string, TValue extends string = string> {
  id: T
  value?: TValue
  title: string
}

interface DropdownSelectProps<T extends DropdownItem> {
  items: Array<T>
  onClickItem: (item: T) => void
  onBlur?: React.FocusEventHandler<HTMLDivElement>
  selectedItem?: T
  disabled?: boolean
}

const ButtonContainer = styled.div<{ open: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  width: 100%;
  border-bottom: 3px solid
    ${(props) => (props.disabled ? props.theme.colors.greyDisabled : props.theme.colors.greyDark)};
  padding: 0.5rem 0.25rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;

  &:focus {
    outline: black solid 2px;
  }
`

const DropdownContentContainer = styled.div<{ open: boolean; disabled?: boolean }>`
  background-color: white;
  filter: drop-shadow(0px 0px 4px rgba(119, 119, 119, 0.25));
  display: ${(props) => (props.open ? "block" : "none")};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  border: 2px solid ${(props) => props.theme.colors.greyDark};
  position: absolute;
  padding: 1.5rem;
  z-index: 3;
  max-height: 60vh;
  overflow-y: scroll;
  right: 0;
`

const CheckingRow = styled(Row)`
  padding: 0.5rem;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borders.buttonRadius};

  &:hover {
    background-color: ${(props) => props.theme.colors.greyLight};
  }
`

function mergeRefs<T>(...refs: React.ForwardedRef<T>[]): React.RefCallback<T> {
  return (node: T) => {
    for (const ref of refs) {
      if (ref) {
        if (typeof ref === "function") ref(node)
        if ("current" in ref) ref.current = node
      }
    }
  }
}

const Dropdown = <T extends DropdownItem>(
  { items, onClickItem, selectedItem, onBlur, disabled }: DropdownSelectProps<T>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)

  const enabled = selectedItem != undefined && !disabled

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const isChecked = (id: string) => {
    return id === selectedItem?.id
  }

  const handleOnClick: React.MouseEventHandler = () => {
    if (!enabled) {
      return
    }
    setOpen((open) => !open)
  }

  const handleClickOutside: EventListener = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setOpen(false)
    }
  }

  const handleKeyDownContainer: React.KeyboardEventHandler = (e) => {
    if (e.key === " ") {
      setOpen((open) => !open)
      e.preventDefault()
    }
  }

  const handleKeyDownItem =
    (item: T): React.KeyboardEventHandler =>
    (e) => {
      if (e.key === " ") {
        onClickItem(item)
      } else if (e.key === "Enter") {
        onClickItem(item)
        setOpen(false)
      }
    }

  return (
    <div
      onClick={handleOnClick}
      role="listbox"
      ref={mergeRefs(dropdownRef, ref)}
      tabIndex={0}
      onBlur={onBlur}
      onKeyDown={handleKeyDownContainer}
    >
      <div style={{ position: "relative" }}>
        <ButtonContainer disabled={!enabled} open={open}>
          <H3 disabled={!enabled} textAlign="left">
            {selectedItem?.title ?? "---"}
          </H3>
          <Spacer spacing={1} />
          <ArrowDropDownIcon color={!enabled ? theme.colors.greyDisabled : undefined} open={open} />
        </ButtonContainer>
        <DropdownContentContainer open={open}>
          <div style={{ width: "max-content", minWidth: 100 }} role="list">
            {items.map((item) => (
              <CheckingRow
                align="center"
                key={item.id}
                role="listitem"
                tabIndex={0}
                onKeyDown={handleKeyDownItem(item)}
                onClick={() => {
                  onClickItem(item)
                }}
              >
                {isChecked(item.id) ? <CheckmarkIcon /> : <Spacer spacing={1.35} />}
                <Spacer spacing={0.5} />
                <H3>{item.title}</H3>
              </CheckingRow>
            ))}
          </div>
        </DropdownContentContainer>
      </div>
    </div>
  )
}

export default React.forwardRef(Dropdown) as <T extends DropdownItem>(
  props: DropdownSelectProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => ReturnType<typeof Dropdown>
