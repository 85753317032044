// Auto generated file, please don't edit manually

import { UIConfig } from "../UIConfig"
import { Metrics } from "../Metrics"
import { StringEditor, EnumEditor, NumberEditor, BoolEditor } from "../editor"
import dayjs from "dayjs"

export type FanControlMode = "off" | "auto" | "max"
export type WifiSecurity = "open" | "wpa" | "wpa2" | "wpa3"

export interface DeviceConfigMotion<T> {
  useBeamInfoId(extraData: T): NumberEditor | undefined
  useFanControlMode(extraData: T): EnumEditor<FanControlMode> | undefined
  useTransmissionIntentEnabled(extraData: T): BoolEditor | undefined
  useWifiEnabled(extraData: T): BoolEditor | undefined
  useWifiTxPower(extraData: T): NumberEditor | undefined
  useWifiChannel(extraData: T): NumberEditor | undefined
  useWifiSsid(extraData: T): StringEditor | undefined
  useWifiBroadcastSsid(extraData: T): BoolEditor | undefined
  useWifiSecurity(extraData: T): EnumEditor<WifiSecurity> | undefined
  useWifiPassword(extraData: T): StringEditor | undefined
}

export function newDeviceConfigMotion<T>(uiConfig: UIConfig<T>): DeviceConfigMotion<T> {
  return {
    useBeamInfoId(extraData: T): NumberEditor | undefined {
      return uiConfig.useNumberValue("beam_info_id", extraData)
    },
    useFanControlMode(extraData: T): EnumEditor<FanControlMode> | undefined {
      return uiConfig.useEnumValue("fan_control_mode", extraData)
    },
    useTransmissionIntentEnabled(extraData: T): BoolEditor | undefined {
      return uiConfig.useBoolValue("transmission_intent_enabled", extraData)
    },
    useWifiEnabled(extraData: T): BoolEditor | undefined {
      return uiConfig.useBoolValue("wifi_enabled", extraData)
    },
    useWifiTxPower(extraData: T): NumberEditor | undefined {
      return uiConfig.useNumberValue("wifi_tx_power", extraData)
    },
    useWifiChannel(extraData: T): NumberEditor | undefined {
      return uiConfig.useNumberValue("wifi_channel", extraData)
    },
    useWifiSsid(extraData: T): StringEditor | undefined {
      return uiConfig.useStringValue("wifi_ssid", extraData)
    },
    useWifiBroadcastSsid(extraData: T): BoolEditor | undefined {
      return uiConfig.useBoolValue("wifi_broadcast_ssid", extraData)
    },
    useWifiSecurity(extraData: T): EnumEditor<WifiSecurity> | undefined {
      return uiConfig.useEnumValue("wifi_security", extraData)
    },
    useWifiPassword(extraData: T): StringEditor | undefined {
      return uiConfig.useStringValue("wifi_password", extraData)
    },
  }
}

export type CarrierPolarization = "H" | "V"
export type DiagnosticsHealth = "Healthy" | "Degraded" | "Critical" | "Unknown"
export type SoftwareUpdateStatus = "Pending" | "Succeeded" | "Failed: reason"
export type SoftwareUpdateType = "StaticDelta" | "Remote" | "Rollback" | "Unknown"

export interface DeviceMetricsMotion {
  useAntennaPositionLat(): number | undefined
  useAntennaPositionLon(): number | undefined
  useBucInfoVersion(): boolean | undefined
  useBucInfoBooting(): boolean | undefined
  useBucTemperatureTimestamp(): dayjs.Dayjs | undefined
  useBucTemperatureAmplifier1(): number | undefined
  useBucTemperatureAmplifier2(): number | undefined
  useBeamListList(): string | undefined
  useCarrierRxLo(): number | undefined
  useCarrierTxLo(): number | undefined
  useCarrierCarrier(): number | undefined
  useCarrierBandwidth(): number | undefined
  useCarrierPolSkew(): number | undefined
  useCarrierPolarization(): CarrierPolarization | undefined
  useConnectivityPing(): boolean | undefined
  useConnectivityDns(): boolean | undefined
  useConnectivityTimestamp(): dayjs.Dayjs | undefined
  useCurrentOrientationAzimuth(): number | undefined
  useCurrentOrientationElevation(): number | undefined
  useCurrentOrientationPolarization(): number | undefined
  useDiagnosticsHealth(): DiagnosticsHealth | undefined
  useFanSpeedsPbBucFan1(): number | undefined
  useFanSpeedsPbBucFan2(): number | undefined
  useFanSpeedsPbModemFan1(): number | undefined
  useFanSpeedsPbModemFan2(): number | undefined
  useFanSpeedsUibExtFan1(): number | undefined
  useFanSpeedsUibExtFan2(): number | undefined
  useFanSpeedsUibIntFan1(): number | undefined
  useFanSpeedsUibIntFan2(): number | undefined
  useGnssSatellites(): number | undefined
  useLnbBeaconStrength(): number | undefined
  useLnbInfoVersion(): number | undefined
  useLnbInfoBooting(): boolean | undefined
  useMotorAnglesTimestamp(): dayjs.Dayjs | undefined
  useMotorAnglesAlpha(): number | undefined
  useMotorAnglesBeta(): number | undefined
  useMotorAnglesGamma(): number | undefined
  useMotorDiagnosticsAlphaTimestamp(): dayjs.Dayjs | undefined
  useMotorDiagnosticsAlphaSpeed(): number | undefined
  useMotorDiagnosticsAlphaCurrent(): number | undefined
  useMotorDiagnosticsAlphaWindingTemp(): number | undefined
  useMotorDiagnosticsAlphaDriveThermalState(): number | undefined
  useMotorDiagnosticsAlphaMotorThermalState(): number | undefined
  useMotorDiagnosticsBetaTimestamp(): dayjs.Dayjs | undefined
  useMotorDiagnosticsBetaSpeed(): number | undefined
  useMotorDiagnosticsBetaCurrent(): number | undefined
  useMotorDiagnosticsBetaWindingTemp(): number | undefined
  useMotorDiagnosticsBetaDriveThermalState(): number | undefined
  useMotorDiagnosticsBetaMotorThermalState(): number | undefined
  useRuntimeMonitorTimestamp(): dayjs.Dayjs | undefined
  useRuntimeMonitorCpuTemp(): number | undefined
  useRuntimeMonitorSocTemp(): number | undefined
  useRuntimeMonitorPbTemp(): number | undefined
  useRuntimeMonitorPbHumidity(): number | undefined
  useRuntimeMonitorUibTemp(): number | undefined
  useRuntimeMonitorUibHumidity(): number | undefined
  useRuntimeMonitorVoltageLevel24v(): number | undefined
  useRuntimeMonitorVoltageLevel5v(): number | undefined
  useRuntimeMonitorVoltageLevel3v3(): number | undefined
  useRuntimeMonitorVoltageLevel1v8(): number | undefined
  useRuntimeMonitorVoltageLevel1v2(): number | undefined
  useSatellitePositionLat(): number | undefined
  useSatellitePositionLon(): number | undefined
  useSignalStatusStrength(): number | undefined
  useSignalStatusReceiving(): boolean | undefined
  useSoftwareUpdateStatus(): SoftwareUpdateStatus | undefined
  useSoftwareUpdateType(): SoftwareUpdateType | undefined
  useSystemTimeTimestamp(): dayjs.Dayjs | undefined
  useTargetOrientationAzimuth(): number | undefined
  useTargetOrientationElevation(): number | undefined
  useTargetOrientationPolarization(): number | undefined
  useTransmissionAllowedStatus(): boolean | undefined
}

export function newDeviceMetricsMotion(metrics: Metrics) {
  return {
    useAntennaPositionLat(): number | undefined {
      return metrics.useNumberValue("antenna_position", "lat")
    },
    useAntennaPositionLon(): number | undefined {
      return metrics.useNumberValue("antenna_position", "lon")
    },
    useBucInfoVersion(): boolean | undefined {
      return metrics.useBoolValue("buc_info", "version")
    },
    useBucInfoBooting(): boolean | undefined {
      return metrics.useBoolValue("buc_info", "booting")
    },
    useBucTemperatureTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("buc_temperature", "timestamp")
    },
    useBucTemperatureAmplifier1(): number | undefined {
      return metrics.useNumberValue("buc_temperature", "amplifier_1")
    },
    useBucTemperatureAmplifier2(): number | undefined {
      return metrics.useNumberValue("buc_temperature", "amplifier_2")
    },
    useBeamListList(): string | undefined {
      return metrics.useStringValue("beam_list", "list")
    },
    useCarrierRxLo(): number | undefined {
      return metrics.useNumberValue("carrier", "rx_lo")
    },
    useCarrierTxLo(): number | undefined {
      return metrics.useNumberValue("carrier", "tx_lo")
    },
    useCarrierCarrier(): number | undefined {
      return metrics.useNumberValue("carrier", "carrier")
    },
    useCarrierBandwidth(): number | undefined {
      return metrics.useNumberValue("carrier", "bandwidth")
    },
    useCarrierPolSkew(): number | undefined {
      return metrics.useNumberValue("carrier", "pol_skew")
    },
    useCarrierPolarization(): CarrierPolarization | undefined {
      return metrics.useEnumValue("carrier", "polarization")
    },
    useConnectivityPing(): boolean | undefined {
      return metrics.useBoolValue("connectivity", "ping")
    },
    useConnectivityDns(): boolean | undefined {
      return metrics.useBoolValue("connectivity", "dns")
    },
    useConnectivityTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("connectivity", "timestamp")
    },
    useCurrentOrientationAzimuth(): number | undefined {
      return metrics.useNumberValue("current_orientation", "azimuth")
    },
    useCurrentOrientationElevation(): number | undefined {
      return metrics.useNumberValue("current_orientation", "elevation")
    },
    useCurrentOrientationPolarization(): number | undefined {
      return metrics.useNumberValue("current_orientation", "polarization")
    },
    useDiagnosticsHealth(): DiagnosticsHealth | undefined {
      return metrics.useEnumValue("diagnostics", "health")
    },
    useFanSpeedsPbBucFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_buc_fan1")
    },
    useFanSpeedsPbBucFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_buc_fan2")
    },
    useFanSpeedsPbModemFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_modem_fan1")
    },
    useFanSpeedsPbModemFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_modem_fan2")
    },
    useFanSpeedsUibExtFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_ext_fan1")
    },
    useFanSpeedsUibExtFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_ext_fan2")
    },
    useFanSpeedsUibIntFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_int_fan1")
    },
    useFanSpeedsUibIntFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_int_fan2")
    },
    useGnssSatellites(): number | undefined {
      return metrics.useNumberValue("gnss", "satellites")
    },
    useLnbBeaconStrength(): number | undefined {
      return metrics.useNumberValue("lnb_beacon", "strength")
    },
    useLnbInfoVersion(): number | undefined {
      return metrics.useNumberValue("lnb_info", "version")
    },
    useLnbInfoBooting(): boolean | undefined {
      return metrics.useBoolValue("lnb_info", "booting")
    },
    useMotorAnglesTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("motor_angles", "timestamp")
    },
    useMotorAnglesAlpha(): number | undefined {
      return metrics.useNumberValue("motor_angles", "alpha")
    },
    useMotorAnglesBeta(): number | undefined {
      return metrics.useNumberValue("motor_angles", "beta")
    },
    useMotorAnglesGamma(): number | undefined {
      return metrics.useNumberValue("motor_angles", "gamma")
    },
    useMotorDiagnosticsAlphaTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("motor_diagnostics_alpha", "timestamp")
    },
    useMotorDiagnosticsAlphaSpeed(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "speed")
    },
    useMotorDiagnosticsAlphaCurrent(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "current")
    },
    useMotorDiagnosticsAlphaWindingTemp(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "winding_temp")
    },
    useMotorDiagnosticsAlphaDriveThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "drive_thermal_state")
    },
    useMotorDiagnosticsAlphaMotorThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "motor_thermal_state")
    },
    useMotorDiagnosticsBetaTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("motor_diagnostics_beta", "timestamp")
    },
    useMotorDiagnosticsBetaSpeed(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "speed")
    },
    useMotorDiagnosticsBetaCurrent(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "current")
    },
    useMotorDiagnosticsBetaWindingTemp(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "winding_temp")
    },
    useMotorDiagnosticsBetaDriveThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "drive_thermal_state")
    },
    useMotorDiagnosticsBetaMotorThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "motor_thermal_state")
    },
    useRuntimeMonitorTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("runtime_monitor", "timestamp")
    },
    useRuntimeMonitorCpuTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "cpu_temp")
    },
    useRuntimeMonitorSocTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "soc_temp")
    },
    useRuntimeMonitorPbTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "pb_temp")
    },
    useRuntimeMonitorPbHumidity(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "pb_humidity")
    },
    useRuntimeMonitorUibTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "uib_temp")
    },
    useRuntimeMonitorUibHumidity(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "uib_humidity")
    },
    useRuntimeMonitorVoltageLevel24v(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_24v")
    },
    useRuntimeMonitorVoltageLevel5v(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_5v")
    },
    useRuntimeMonitorVoltageLevel3v3(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_3v3")
    },
    useRuntimeMonitorVoltageLevel1v8(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_1v8")
    },
    useRuntimeMonitorVoltageLevel1v2(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_1v2")
    },
    useSatellitePositionLat(): number | undefined {
      return metrics.useNumberValue("satellite_position", "lat")
    },
    useSatellitePositionLon(): number | undefined {
      return metrics.useNumberValue("satellite_position", "lon")
    },
    useSignalStatusStrength(): number | undefined {
      return metrics.useNumberValue("signal_status", "strength")
    },
    useSignalStatusReceiving(): boolean | undefined {
      return metrics.useBoolValue("signal_status", "receiving")
    },
    useSoftwareUpdateStatus(): SoftwareUpdateStatus | undefined {
      return metrics.useEnumValue("software_update", "status")
    },
    useSoftwareUpdateType(): SoftwareUpdateType | undefined {
      return metrics.useEnumValue("software_update", "type")
    },
    useSystemTimeTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("system_time", "timestamp")
    },
    useTargetOrientationAzimuth(): number | undefined {
      return metrics.useNumberValue("target_orientation", "azimuth")
    },
    useTargetOrientationElevation(): number | undefined {
      return metrics.useNumberValue("target_orientation", "elevation")
    },
    useTargetOrientationPolarization(): number | undefined {
      return metrics.useNumberValue("target_orientation", "polarization")
    },
    useTransmissionAllowedStatus(): boolean | undefined {
      return metrics.useBoolValue("transmission_allowed", "status")
    },
  }
}
